import React from "react";

interface ContainerProps {
  children?: React.ReactNode;
  backgroundColor?: "transparent" | "white";
  className?: string | undefined;
}

const Container: React.FC<ContainerProps> = ({
  children,
  backgroundColor = "transparent",
  className,
}) => {
  return (
    <div
      className={"w-full " + className}
      style={{
        background: backgroundColor === "transparent" ? "transparent" : "white",
      }}
    >
      <div className="mx-auto max-w-6xl px-4">{children}</div>
    </div>
  );
};

export default Container;
