import React from "react";
import { Heading, Subheading } from "../../components/heading";
import { Divider } from "../../components/divider";

import { Strong, Text, TextLink } from "../../components/text";
import HeroSection from "../../sections/hero/hero-section";
import Container from "../../components/container";
import {
  ProductBody,
  ProductCallToAction,
  ProductDescription,
  ProductDisplay,
  ProductFeature,
  ProductFeatureItem,
  ProductHeader,
  ProductHeading,
  ProductSection,
  ProductSlogan,
} from "../../sections/product/product-section";
import { BoltIcon, CpuChipIcon, LockClosedIcon, PaintBrushIcon } from "@heroicons/react/16/solid";
import { Button } from "../../components/button";
import Card from "../../components/card";

interface KitRouteProps {}

const KitRoute: React.FC<KitRouteProps> = () => {
  return (
    <>
      <HeroSection
        heading={"Digitalize Kit"}
        primaryAction={"Contact Sales"}
        subtitle="Transform your infrastructure with JUHUU. Effortlessly connect your products to the cloud platform, enabling real-time control and integration without any complexity."
        primaryActionHref="/contact"
        // secondaryAction="Order Test Kit"
        // secondaryActionHref="https://docs.juhuu.app/articles/670502291113f7de8a869187"
      />
      <Container>
        <Heading>Overview</Heading>
        <Text>
          The JUHUU Digitalization Kit allows you to transform your mobility and
          infrastructure products into digital solutions effortlessly. With our
          modular plug-and-play kits, you can integrate digital features into
          your products, accessing our powerful software platform. Whether you
          are developing new infrastructure or upgrading existing systems, JUHUU
          makes it simple - no in-depth expertise needed. You can focus on
          delivering value while we handle the digital transformation.
        </Text>
        {/* Übersicht mit JUHUU Plattform wolke und den IoT Geräten drunter */}
      </Container>
      <Container className="mb-32 mt-16">
        <Heading>Help us understand your needs</Heading>
        <Text className="mb-8">Choose your use case scenario.</Text>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-16">
          <div className="flex flex-col">
            <Heading className="mb-2">New infrastructure</Heading>
            <Card
              href="https://docs.juhuu.app/articles/67039ea135ed1ec9444a71c5"
              title="I am a manufacturer of products and want to digitalize them"
              description="We provide manufacturers with plug-and-play digital solutions for new mobility and infrastructure products. You can easily integrate our kits, making your products ready for the future without needing in-depth digital expertise."
              className="flex-grow"
            />
          </div>
          <div className="flex flex-col">
            <Heading className="mb-2">Existing infrastructure</Heading>
            <Card
              href="https://docs.juhuu.app/articles/67039ed335ed1ec9444a71e8"
              title="I have existing infrastructure and I want to upgrade"
              description="Our kits allow you to quickly digitalize your existing systems. With minimal effort, we help you connect your infrastructure to our platform for better management and optimization."
              className="flex-grow"
            />
          </div>
        </div>
      </Container>

      <Container>
        <ProductSection>
          <ProductBody>
            <ProductHeader>
              <CpuChipIcon />
              <ProductHeading>IoT Module</ProductHeading>
            </ProductHeader>
            <ProductSlogan>Your Gateway to Digital Infrastructure</ProductSlogan>
            <ProductDescription>
            The IoT Module allows you to connect your infrastructure to the internet, letting you control locks and devices through our platform. It supports a variety of devices with options like RS485, RS232, or Ethernet. You can manage multiple systems effortlessly, and we'll even customize the module to fit your specific needs—making sure it works exactly how you want it.
            </ProductDescription>
            <ProductCallToAction>
              <Button href={"https://dashboard.juhuu.app/"}>Start</Button>
              <Button plain href="/contact">Contact Sales</Button>
            </ProductCallToAction>
            <ProductFeature>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/67039f7135ed1ec9444a720c">Specsheet IoT Module</TextLink> for detailed technical specifications.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/67039ff635ed1ec9444a7240">Capabilities of the IoT Module</TextLink> for flexible device control, connectivity, and power options tailored to your infrastructure needs
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/670502291113f7de8a869187">Free Test Kit</TextLink> for the fastest way to test our system free of charge.
              </ProductFeatureItem>
            </ProductFeature>
          </ProductBody>
          <ProductDisplay></ProductDisplay>
        </ProductSection>
        <ProductSection>
          <ProductBody>
            <ProductHeader>
              <LockClosedIcon />
              <ProductHeading>Locking System</ProductHeading>
            </ProductHeader>
            <ProductSlogan>Security that adapts to your needs</ProductSlogan>
            <ProductDescription>
            Our Locking System includes a Lock Control Unit and an electric lock, both integrated with the IoT Module. This system enables you to digitally control and manage locks via our platform. It's fully customizable, designed to meet your specific needs, whether you're managing a single lock or a larger infrastructure.
            </ProductDescription>
            <ProductCallToAction>
              <Button href={"https://dashboard.juhuu.app/"}>Start</Button>
              <Button plain href="/contact">Contact Sales</Button>
            </ProductCallToAction>
            <ProductFeature>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/6704ffeb1113f7de8a8690a3">Compatible locks</TextLink> for integrating a variety of electric locks to suit different needs.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/670500951113f7de8a8690c9">Lock controllers</TextLink> to manage up to 48 locks, with flexible options for installations.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/6705012f1113f7de8a8690f1">Customization</TextLink> for tailoring the locking system to meet your specific security requirements.
              </ProductFeatureItem>
            </ProductFeature>
          </ProductBody>
          <ProductDisplay></ProductDisplay>
        </ProductSection>
        <ProductSection>
          <ProductBody>
            <ProductHeader>
              <BoltIcon />
              <ProductHeading>Plug-and-Play Kit</ProductHeading>
            </ProductHeader>
            <ProductSlogan>Plug in, install, and ready to go </ProductSlogan>
            <ProductDescription>
            The Plug and Play Kit includes our IoT Module and our customizable Locking System, providing an all-in-one solution for your products. Simply install the kit into your infrastructure products, and you are instantly connected to the JUHUU software platform and all their feautes—no complex setup required.
            </ProductDescription>
            <ProductCallToAction>
              <Button href={"https://dashboard.juhuu.app/"}>Start</Button>
              <Button plain href="/contact">Contact Sales</Button>
            </ProductCallToAction>
            <ProductFeature>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/670502291113f7de8a869187">Free Test Kit</TextLink> for the fastest way to test our system free of charge.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/670501d61113f7de8a869164">Solar & battery</TextLink> for fully autonomous, off-grid operation of your infrastructure.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/67039ff635ed1ec9444a7240">Capabilities of the IoT Module </TextLink> for flexible device control, connectivity, and power options tailored to your infrastructure needs
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/6704ffeb1113f7de8a8690a3">Compatible locks</TextLink> for integrating a variety of electric locks to suit different needs.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/670500951113f7de8a8690c9">Lock control units</TextLink> to manage up to 48 locks, with flexible options for installations.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/6705012f1113f7de8a8690f1">Customization</TextLink> for tailoring the locking system to meet your specific security requirements.
              </ProductFeatureItem>
            </ProductFeature>
          </ProductBody>
          <ProductDisplay></ProductDisplay>
        </ProductSection>
        <ProductSection>
          <ProductBody>
            <ProductHeader>
              <PaintBrushIcon />
              <ProductHeading>Solar & Battery</ProductHeading>
            </ProductHeader>
            <ProductSlogan>Fully autonomous, off-grid operation of your infrastructure</ProductSlogan>
            <ProductDescription>
            We let you run your infrastructure completely off-grid with solar and battery power. No need to rely on external power sources—just install the system, and you are ready to go, even in remote locations. 
            </ProductDescription>
            <ProductCallToAction>
              <Button href={"https://dashboard.juhuu.app/"}>Start</Button>
              <Button plain href="/contact">Contact Sales</Button>
            </ProductCallToAction>
            <ProductFeature>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/670501d61113f7de8a869164">Solar & battery</TextLink> for independent, off-grid operation of your systems.
              </ProductFeatureItem>
            </ProductFeature>
          </ProductBody>
          <ProductDisplay></ProductDisplay>
        </ProductSection>
        <ProductSection>
          <ProductBody>
            <ProductHeader>
              <PaintBrushIcon />
              <ProductHeading>Custom Connected Products</ProductHeading>
            </ProductHeader>
            <ProductSlogan>Tailored solutions for your unique system</ProductSlogan>
            <ProductDescription>
            If you already have a digital system or need something beyond our Plug and Play Kit, we have got you covered. We offer customization services where we adapt our electronics to communicate with your existing system or control the components you need. Whether it is a gate, rotating box system, or something entirely unique, we’ll make it work for you.
            </ProductDescription>
            <ProductCallToAction>
              <Button href={"https://dashboard.juhuu.app/"}>Start</Button>
              <Button plain href="/contact">Contact Sales</Button>
            </ProductCallToAction>
            <ProductFeature>
            <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/67039f7135ed1ec9444a720c">Specsheet IoT Module</TextLink> for detailed technical specifications.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/67039ff635ed1ec9444a7240">Capabilities of the IoT Module</TextLink> for flexible device control, connectivity, and power options tailored to your infrastructure needs
              </ProductFeatureItem>
            </ProductFeature>
          </ProductBody>
          <ProductDisplay></ProductDisplay>
        </ProductSection>
      </Container>
      <div style={{
        height: "100px",
      }}/>
    </>
  );
};

export default KitRoute;
