import React from "react";
import { InlineWidget } from "react-calendly";
import { Heading } from "./heading";
import { Strong, Text } from "./text";
import { useCookie } from "../context/CookieContext";

interface ScheduleMeetingProps {}

const ScheduleMeeting: React.FC<ScheduleMeetingProps> = ({}) => {
  const { allowCookies } = useCookie();
  return (
    <>
      <Heading>Schedule a meeting</Heading>
      <Text>
        Do you have a specific use case in mind or want to explore a partnership
        with JUHUU? Schedule a meeting with Ben Müller-Niklas! Simply choose a
        time that works for you using the calendar below. Feel free to send us
        details about your needs, ideas, or use case via email beforehand, so we
        can better prepare for our conversation.
      </Text>
      <Text className="mt-8">
        Text us at <Strong>office@juhuu.app</Strong>
      </Text>
      <Text className="mb-8">
        Call us at <Strong>+43 660 9919 009</Strong>
      </Text>
      {allowCookies === true && (
        <>
          <Text className="mb-8">
            If you do not want to schedule a meeting online, reach out to us at
            office@juhuu.app or call us at +43 660 9919 009!
          </Text>

          <InlineWidget
            url="https://calendly.com/ben-muellerniklas/meeting"
            styles={{
              height: "1000px",
              width: "100%",
            }}
          />
        </>
      )}
    </>
  );
};

export default ScheduleMeeting;
