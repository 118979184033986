import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Navbar,
  NavbarDivider,
  NavbarItem,
  NavbarSection,
  NavbarSpacer,
} from "./components/navbar";
import logo from "./routes/navbar/logo.png";
import AnimatedGradient from "./components/animated-gradient";
import Container from "./components/container";
import Footer from "./routes/footer/Footer";
import { Bars2Icon, XCircleIcon } from "@heroicons/react/16/solid";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from "./components/dialog";
import { Button } from "./components/button";

interface PageProps {
  children: React.ReactNode;
}

const Page: React.FC<PageProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false); // State for burger menu

  const navItems = [
    { label: "App", url: "app" },
    { label: "Dashboard", url: "dashboard" },
    { label: "Digitalize Kit", url: "kit" },
    { label: "Marketplace", url: "marketplace" },
    { label: "Developers", url: "developer" },
    { label: "Pricing", url: "pricing" },
  ];

  return (
    <>
      <div
        className={"light"}
        style={{
          overflowX: "hidden",
          minHeight: "100vh", // Ensure full-page height
          position: "relative",
        }}
      >
        <Container>
          <Navbar
            style={{
              position: "relative",
              zIndex: 1,
            }}
          >
            {/* Logo (Hidden on mobile, shown on large screens) */}
            <img
              onClick={() => navigate("/")}
              className="cursor-pointer hidden lg:block" // Hidden on mobile, visible on lg screens
              src={logo}
              style={{
                width: "120px",
                height: "auto",
              }}
            />

            {/* Burger Menu Icon (shown only on mobile) */}
            <div className="lg:hidden flex items-center">
              <button
                onClick={() => setMenuOpen(!menuOpen)}
                className="text-gray-600 focus:outline-none"
              >
                <Bars2Icon className="h-8 w-8" color="white" />
              </button>
            </div>

            {/* Desktop navigation (hidden on mobile) */}
            <NavbarSection className="hidden lg:flex">
              {navItems.map(({ label, url }) => (
                <NavbarItem
                  key={label}
                  href={"/" + url}
                  current={location.pathname.split("/")[1] === url}
                >
                  {label}
                </NavbarItem>
              ))}
            </NavbarSection>

            <NavbarSpacer />

            {/* "Get Started" button (shown on all screen sizes) */}
            <NavbarSection>
              <div
                className="cursor-pointer bg-white rounded-full flex items-center justify-center py-2 px-4 shadow-xl"
                onClick={() => navigate("/start")}
              >
                <p className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-green-500 font-bold">
                  Get started now!
                </p>
              </div>
            </NavbarSection>
          </Navbar>

          {/* Mobile navigation menu (shown only when the menu is open) */}
          {menuOpen && (
            <div className="lg:hidden absolute bg-white w-full shadow-md">
              {navItems.map(({ label, url }) => (
                <NavbarItem
                  key={label}
                  href={"/" + url}
                  current={location.pathname.split("/")[1] === url}
                  className="block text-center py-4"
                >
                  {label}
                </NavbarItem>
              ))}
              <div className="py-4 text-center">
                <NavbarItem href={"/contact"}>Contact Sales</NavbarItem>
              </div>
            </div>
          )}
        </Container>

        <AnimatedGradient
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
          }}
          clipPath="polygon(0% 0%, 100% 0%, 100% 50%, 0% 100%)"
        />
        <div
          style={{
            position: "relative",
            zIndex: 1,
            minHeight: "calc(100vh - 200px)", // Adjust height based on footer/gradient size
          }}
        >
          {children}
        </div>
        <div
          style={{
            position: "relative",
            zIndex: 1,
          }}
        >
          <Footer />
        </div>
        <AnimatedGradient
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 0,
            height: "200px", // Adjust height as needed
          }}
          clipPath="polygon(0% 20%, 100% 0%, 100% 100%, 0% 100%)"
        />
      </div>
      {/* Mobile navigation menu (shown only when the menu is open) */}
      <Dialog
        open={menuOpen}
        onClose={setMenuOpen}
        style={{
          zIndex: 100,
          position: "absolute",
        }}
      >
        <DialogBody>
          {navItems.map(({ label, url }) => (
            <p
              className={
                "py-2 px-4 my-4 text-lg font-bold rounded-full cursor-pointer " +
                (location.pathname.split("/")[1] === url ? "bg-zinc-200" : "")
              }
              key={label}
              onClick={() => {
                setMenuOpen(false);
                navigate("/" + url);
              }}
              // current={location.pathname.split("/")[1] === url}
            >
              {label}
            </p>
          ))}
        </DialogBody>
        <DialogActions>
          <Button onClick={() => setMenuOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Page;
