import React from "react";
import { Heading, Subheading } from "../../components/heading";
import { Divider } from "../../components/divider";

import { Text, TextLink } from "../../components/text";
import HeroSection from "../../sections/hero/hero-section";
import CallToActionSection from "../../sections/callToAction/call-to-action-section";
import Container from "../../components/container";

interface StartRouteProps {}

const StartRoute: React.FC<StartRouteProps> = () => {
  return (
    <>
      <HeroSection
        heading={"Get started transforming your business."}
        primaryAction="Contact Sales"
        primaryActionHref="/contact"
        secondaryAction="Sign up"
        secondaryActionHref="https://identity.juhuu.app"
      />
      <Container className="mb-32">
        <CallToActionSection />
      </Container>
    </>
  );
};

export default StartRoute;
