import React from "react";
import { Heading, Subheading } from "../../components/heading";
import { Divider } from "../../components/divider";

import { Text, TextLink } from "../../components/text";
import HeroSection from "../../sections/hero/hero-section";

interface ErrorRouteProps {}

const ErrorRoute: React.FC<ErrorRouteProps> = () => {
  return (
    <>
      <HeroSection heading={"This page does not exist."} />
    </>
  );
};

export default ErrorRoute;
