import React from "react";
import { Heading, Subheading } from "../../components/heading";
import { Text, TextLink } from "../../components/text";
import HeroSection from "../../sections/hero/hero-section";
import team from "./team.webp";
import ben from "./ben.jpg";
import michael from "./michael.jpg";
import marc from "./marc.jpg";
import moritz from "./moritz.png";
import simon from "./simon.jpg";
import quentin from "./quentin.jpg";
import Container from "../../components/container";
import ScheduleMeeting from "../../components/schedule-meeting";
import { Li, Ul } from "../../components/list";

interface BoxProps {
  heading: string | null | undefined;
  subheading: string | null | undefined;
  imgSrc?: string;
  email?: string;
  phone?: string;
}

const Box: React.FC<BoxProps> = ({
  heading,
  subheading,
  imgSrc,
  email,
  phone,
}) => {
  return (
    <div
      className="bg-white flex flex-col justify-start items-start shadow-md border border-zinc-300"
      style={{
        overflow: "hidden",
        borderRadius: "0.75rem",
      }}
    >
      <img
        src={imgSrc}
        style={{
          width: "100%",
          height: "300px",
          objectFit: "cover",
          objectPosition: "top",
        }}
      />
      <Heading className="mx-8 mt-8">{heading}</Heading>
      <Subheading className="mx-8 pb-4 text-zinc-500/75">
        {subheading}
      </Subheading>
      <div className="px-8 pb-6 w-full">
        <Ul>
          {email !== undefined && <Li>{email}</Li>}
          {phone !== undefined && <Li>{phone}</Li>}
        </Ul>
      </div>
    </div>
  );
};

interface ContactRouteProps {}

const ContactRoute: React.FC<ContactRouteProps> = () => {
  return (
    <>
      <HeroSection
        heading={"Contact"}
        subtitle="No matter the request, we are here to help. Let's schedule a meeting today and get to know eachother."
      >
        <img
          src={team}
          alt=""
          style={{
            position: "relative",
            width: "100%",
            left: "100px",
            top: "-70px",
          }}
        />
      </HeroSection>
      <Container className="mb-16"></Container>
      <Container>
        <ScheduleMeeting />
      </Container>
      <Container>
        <Heading>The Team behind JUHUU</Heading>
        <Text className="mb-8">
          We are a team of passionate individuals with a shared vision of a
          sustainable future. We are here to help you with your digital
          transformation. Contact us today and let's get started!
        </Text>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          <Box
            heading={"Ben Müller-Niklas"}
            subheading={"CEO"}
            imgSrc={ben}
            email="ben.muellerniklas@juhuu.app"
            phone="+43 660 9919 009"
          />
          <Box
            heading={"Moritz Wallner"}
            subheading={"CTO"}
            imgSrc={moritz}
            email="moritz.wallner@juhuu.app"
            phone="+49 176 82095563"
          />
          <Box
            heading={"Michael Übermasser"}
            subheading={"Software Engineer"}
            imgSrc={michael}
            email="michael.uebermasser@juhuu.app"
            phone="+43 660 9919 009"
          />
          <Box
            heading={"Simon Köfinger"}
            subheading={"Hardware Engineer"}
            imgSrc={simon}
            email="simon.koefinger@juhuu.app"
          />
          <Box
            heading={"Quentin Binder"}
            subheading={"Software Engineer"}
            imgSrc={quentin}
            email="quentin.binder@juhuu.app"
          />
          <Box
            heading={"Marc Müller-Niklas"}
            subheading={"MARCeting"}
            imgSrc={marc}
            email="marc.muellerniklas@juhuu.app"
          />
        </div>
      </Container>
    </>
  );
};

export default ContactRoute;
