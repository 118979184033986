import React from "react";
import { Heading, Subheading } from "../../components/heading";
import { Strong, Text, TextLink } from "../../components/text";
import ReactMarkdown from "react-markdown";
import { Divider } from "../../components/divider";
import { Button } from "../../components/button";
import oebb from "./oebb.webp";
import wintersteiger from "./wintersteiger.webp";
import gablitz from "./gablitz.webp";
import repark from "./repark.webp";
import wheelylift from "./wheelylift.webp";
import mauerbach from "./mauerbach.webp";
import wienerwald from "./wienerwald.webp";
import Container from "../../components/container";

interface PartnerSectionProps {}

const PartnerSection: React.FC<PartnerSectionProps> = ({}) => {
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-44 gap-y-16 px-16 mb-32 items-center justify-items-center py-8">
        <img
          src={wintersteiger}
          style={{
            height: "70px",
            width: "auto",
            display: "block",
            objectFit: "contain",
          }}
        />
        <img
          src={gablitz}
          style={{
            width: "auto",
            height: "70px",
            display: "block",
            objectFit: "contain",
          }}
        />
        <img
          src={oebb}
          style={{
            width: "auto",
            height: "70px",
            display: "block",
            objectFit: "contain",
          }}
        />
        <img
          src={repark}
          style={{
            width: "auto",
            height: "70px",
            display: "block",
            objectFit: "contain",
          }}
        />

        <img
          src={wheelylift}
          style={{
            width: "auto",
            height: "70px",
            objectFit: "contain",
          }}
          className="hidden md:block"
        />
        <img
          src={mauerbach}
          style={{
            width: "auto",
            height: "70px",
            objectFit: "contain",
          }}
          className="hidden md:block"
        />
        <img
          src={wienerwald}
          style={{
            width: "auto",
            height: "70px",
            objectFit: "contain",
          }}
          className="hidden md:block"
        />
      </div>
    </>
  );
};

export default PartnerSection;
