import React from "react";
import { Heading, Subheading } from "../../components/heading";
import { Strong, Text, TextLink } from "../../components/text";
import ReactMarkdown from "react-markdown";
import { Divider } from "../../components/divider";
import { Button } from "../../components/button";
import Container from "../../components/container";

interface HeroSectionProps {
  heading: string;
  subtitle?: string;
  primaryAction?: string;
  primaryActionHref?: string;
  secondaryAction?: string;
  secondaryActionHref?: string;
  children?: React.ReactNode;
}

const HeroSection: React.FC<HeroSectionProps> = ({
  heading,
  subtitle,
  primaryAction,
  secondaryAction,
  children,
  primaryActionHref = "#",
  secondaryActionHref,
}) => {
  return (
    <Container>
      <div
        className="flex flex-col lg:flex-row mt-32 mb-32"
        style={{
          position: "relative",
        }}
      >
        {/* Left section: Full width on smaller devices */}
        <div className="w-full lg:w-2/5">
          <p className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold leading-10 sm:leading-snug md:leading-tight lg:leading-tight">
            {heading}
          </p>
          <p
            style={{
              fontSize: "1.3rem",
              marginTop: "1rem",
            }}
          >
            {subtitle}
          </p>
          <div className="mt-8 flex gap-4 flex-row">
            {primaryAction !== undefined && (
              <Button href={primaryActionHref}>{primaryAction}</Button>
            )}

            {secondaryAction !== undefined && (
              <Button plain href={secondaryActionHref}>
                {secondaryAction}
              </Button>
            )}
          </div>
        </div>

        {/* Right section: Hidden on smaller devices */}
        <div className="hidden lg:block lg:w-1/2">{children}</div>
      </div>
    </Container>
  );
};

export default HeroSection;
