import React from "react";
import { Heading, Subheading } from "../../components/heading";
import { Divider } from "../../components/divider";

import { Text, TextLink } from "../../components/text";
import HeroSection from "../../sections/hero/hero-section";
import { ArrowRightEndOnRectangleIcon, BanknotesIcon, CursorArrowRaysIcon, DevicePhoneMobileIcon, LifebuoyIcon, PaintBrushIcon, PhoneIcon, SparklesIcon, UserGroupIcon } from "@heroicons/react/16/solid";
import { Button } from "../../components/button";
import {
  ProductBody,
  ProductCallToAction,
  ProductDescription,
  ProductDisplay,
  ProductFeature,
  ProductFeatureItem,
  ProductHeader,
  ProductHeading,
  ProductSection,
  ProductSlogan,
} from "../../sections/product/product-section";
import Container from "../../components/container";
import AppStoreImage from "../../components/apple/app-store-image";
import PlayStoreImage from "../../components/google/play-store-image";

interface AppRouteProps {}

const AppRoute: React.FC<AppRouteProps> = () => {
  return (
    <>
      <HeroSection
        heading={"Smartphone App"}
        subtitle="JUHUU App is the link between your customers and your service offerings. The moblity and infrastructure services you integrate into JUHUU can be rented by users through our centralized app."
      />
      <Container>
        <ProductSection>
          <ProductBody>
            <ProductHeader>
              <DevicePhoneMobileIcon />
              <ProductHeading>All in One App</ProductHeading>
            </ProductHeader>
            <ProductSlogan>
              One app. Multiple providers. Endless possibilities
            </ProductSlogan>
            <ProductDescription>
              JUHUU is a central platform that aggregates various providers from
              the shared mobility and infrastructure sectors. Users can easily
              discover, book, and use services from multiple providers with the
              JUHUU app. The goal is to create a unified platform that
              integrates a large number of service providers in these key
              sectors.
            </ProductDescription>
            <ProductCallToAction>
              <Button href={"https://dashboard.juhuu.app/"}>Start</Button>
              <Button plain href="/contact">Contact Sales</Button>
            </ProductCallToAction>
            <ProductFeature>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/6703980035ed1ec9444a6ef5">Partner Companies</TextLink> for a wide range
                of mobility services.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/6703980035ed1ec9444a6ef5">Benefits as a Partner Company</TextLink> and
                expand your reach.
              </ProductFeatureItem>
            </ProductFeature>
          </ProductBody>
          <ProductDisplay><div className="grid grid-cols-2 gap-4">
          <AppStoreImage className="w-44"/>
          <PlayStoreImage className="w-44"/>
        </div></ProductDisplay>
        </ProductSection>
        <ProductSection>
          <ProductBody>
            <ProductHeader>
              <BanknotesIcon />
              <ProductHeading>Payment Service</ProductHeading>
            </ProductHeader>
            <ProductSlogan>
              Effortless payments across borders and currencies
            </ProductSlogan>
            <ProductDescription>
              The JUHUU App supports over 25 currencies and payment methods,
              allowing users to book services effortlessly. Integrated with
              JUHUU's invoicing, it ensures accurate, categorized payments.
              Flexible tariff systems like pay-as-you-go, time-based rentals,
              and reservations are supported, with options to charge or reserve
              funds.
            </ProductDescription>
            <ProductCallToAction>
              <Button href={"https://dashboard.juhuu.app/"}>Start</Button>
              <Button plain href="/contact">Contact Sales</Button>
            </ProductCallToAction>
            <ProductFeature>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/6703988035ed1ec9444a6f13">Payment methods</TextLink> for a wide
                selection of currencies and global options. selection of shared
                mobility and infrastructure services.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/67011c5435ed1ec9444a3b37">Payment service</TextLink> for real-time
                payment processing and fund authorization.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/67011c5435ed1ec9444a3b37">Invoice</TextLink>for automated creation,
                categorization, and tracking of payments.
              </ProductFeatureItem>
            </ProductFeature>
          </ProductBody>
          <ProductDisplay></ProductDisplay>
        </ProductSection>
        <ProductSection>
          <ProductBody>
            <ProductHeader>
              <UserGroupIcon />
              <ProductHeading>Existing Userbase</ProductHeading>
            </ProductHeader>
            <ProductSlogan>
              Instant access to a thriving user community
            </ProductSlogan>
            <ProductDescription>
              Operators instantly tap into JUHUU's associated user base,
              allowing them to be discovered and booked by existing users and
              those of partner companies. New operators benefit from a
              ready-to-go audience and the platform's organic growth, expanding
              their visibility as more users engage with their services.
            </ProductDescription>
            <ProductCallToAction>
              <Button href={"https://dashboard.juhuu.app/"}>Start</Button>
              <Button plain href="/contact">Contact Sales</Button>
            </ProductCallToAction>
            <ProductFeature>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/66e9fd559f98e98c8be2354e">Ready user base</TextLink> for immediate
                access to customers.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/670398c635ed1ec9444a6f34">Increased visibility</TextLink> for growing
                your service alongside the platform.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/670398fb35ed1ec9444a6f52">Shared growth</TextLink> for benefiting from
                the platform's expanding reach.
              </ProductFeatureItem>
            </ProductFeature>
          </ProductBody>
          <ProductDisplay></ProductDisplay>
        </ProductSection>
        <ProductSection>
          <ProductBody>
            <ProductHeader>
              <CursorArrowRaysIcon />
              <ProductHeading>Optimized Usability</ProductHeading>
            </ProductHeader>
            <ProductSlogan>Simplifying every touchpoint</ProductSlogan>
            <ProductDescription>
              Crafted with the latest insights in design, JUHUU is optimized for
              intuitive and fluid navigation. Built for responsiveness, the app
              reduces wait times and adapts to all devices. Features like JUHUU
              Buddy enhance accessibility, making it easier for users to engage.
            </ProductDescription>
            <ProductCallToAction>
              <Button href={"https://dashboard.juhuu.app/"}>Start</Button>
              <Button plain href="/contact">Contact Sales</Button>
            </ProductCallToAction>
            <ProductFeature>
              <ProductFeatureItem>
              <TextLink target="_blank" href="https://docs.juhuu.app/articles/67011e6e35ed1ec9444a3c1d">
                  JUHUU Buddy
                </TextLink>{" "} for enhanced
                accessibility and user support.
              </ProductFeatureItem>
            </ProductFeature>
          </ProductBody>
          <ProductDisplay></ProductDisplay>
        </ProductSection>
        <ProductSection>
          <ProductBody>
            <ProductHeader>
              <LifebuoyIcon />
              <ProductHeading>Accessibility</ProductHeading>
            </ProductHeader>
            <ProductSlogan>Breaking barriers for every user</ProductSlogan>
            <ProductDescription>
              Accessibility is a priority. With support for over 14 languages
              and the AI-powered JUHUU Buddy, users of all technical levels can
              easily access services. The AI assistant helps users with
              questions or problems in their native language. Additionally,
              JUHUU ensures international availability in more than 30
              countries, benefiting both users and operators.
            </ProductDescription>
            <ProductCallToAction>
              <Button href={"https://dashboard.juhuu.app/"}>Start</Button>
              <Button plain href="/contact">Contact Sales</Button>
            </ProductCallToAction>
            <ProductFeature>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/67039a1335ed1ec9444a6fd4">Multiple languages</TextLink> for offering
                services in 14+ languages.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/67039a4135ed1ec9444a6ff7">Global availability</TextLink> for providing
                localized services in 30+ countries.
              </ProductFeatureItem>
            </ProductFeature>
          </ProductBody>
          <ProductDisplay></ProductDisplay>
        </ProductSection>
        <ProductSection>
          <ProductBody>
            <ProductHeader>
              <ArrowRightEndOnRectangleIcon />
              <ProductHeading>Third-Party Integration</ProductHeading>
            </ProductHeader>
            <ProductSlogan>
              Extend your platform, broaden your reach
            </ProductSlogan>
            <ProductDescription>
              Our system integrates seamlessly with existing consumer apps,
              enabling partner platforms to access a variety of operator
              services. Platforms gain additional features to offer their users,
              while operators benefit from increased visibility and bookings
              across multiple apps.
            </ProductDescription>
            <ProductCallToAction>
              <Button href={"https://dashboard.juhuu.app/"}>Start</Button>
              <Button plain href="/contact">Contact Sales</Button>
            </ProductCallToAction>
            <ProductFeature>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/67039b4035ed1ec9444a7033">Partner frontend apps </TextLink> for easy
                integration with existing platforms.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/6703980035ed1ec9444a6ef5">Benefits for platforms & operators</TextLink>{" "}
                for more services and visibility.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/670398fb35ed1ec9444a6f52">Revenue share model</TextLink> for shared
                commissions with partners.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/67039b0635ed1ec9444a701d">Integration process</TextLink> for
                straightforward platform connections.
              </ProductFeatureItem>
            </ProductFeature>
          </ProductBody>
          <ProductDisplay></ProductDisplay>
        </ProductSection>
        <ProductSection>
          <ProductBody>
            <ProductHeader>
              <SparklesIcon />
              <ProductHeading>JUHUU Buddy</ProductHeading>
            </ProductHeader>
            <ProductSlogan>AI-driven support in 14+ languages.</ProductSlogan>
            <ProductDescription>
              JUHUU Buddy provides 24/7 AI-powered support, delivering instant
              responses to user inquiries. By accessing a rich knowledge base
              and user data, it offers smooth, automated assistance, ensuring a
              seamless support experience across 14+ languages. Whether
              resolving issues or guiding users, JUHUU Buddy enhances user
              engagement with reliable, multilingual support.
            </ProductDescription>
            <ProductCallToAction>
              <Button href={"https://dashboard.juhuu.app/"}>Start</Button>
              <Button plain href="/contact">Contact Sales</Button>
            </ProductCallToAction>
            <ProductFeature>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/67039a1335ed1ec9444a6fd4">Supported languages </TextLink> for offering
                services in 14+ languages.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/67039a4135ed1ec9444a6ff7">Global Availability </TextLink> for providing
                localized services in 30+ countries.
              </ProductFeatureItem>
            </ProductFeature>
          </ProductBody>
          <ProductDisplay></ProductDisplay>
        </ProductSection>
        <ProductSection>
          <ProductBody>
            <ProductHeader>
              <PhoneIcon />
              <ProductHeading>Service Solutions</ProductHeading>
            </ProductHeader>
            <ProductSlogan>
              Empowering operators with reliable tools
            </ProductSlogan>
            <ProductDescription>
              We offer a suite of in-house services that simplify operations for
              operators. From invoicing and user management to payments and
              automatic updates, JUHUU handles the technical side, letting
              operators focus on growth. Seamless onboarding and device
              management are included through JUHUU Identity.
            </ProductDescription>
            <ProductCallToAction>
              <Button href={"https://dashboard.juhuu.app/"}>Start</Button>
              <Button plain href="/contact">Contact Sales</Button>
            </ProductCallToAction>
            <ProductFeature>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/67011c5435ed1ec9444a3b37">Invoicing and billing services </TextLink>{" "}
                for simplified financial operations.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/66e9fd559f98e98c8be2354e">User management dervices </TextLink> for easy
                administration.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/67039bc335ed1ec9444a705a">JUHUU Identity</TextLink> for cross-platform
                registration and login.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/67011c5435ed1ec9444a3b37">Payment services</TextLink> for secure
                payments.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/67039c1c35ed1ec9444a707d">Update services</TextLink> for automatic
                software updates.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://docs.juhuu.app/articles/670394b835ed1ec9444a6e8c">Device Management</TextLink> for
                comprehensive control over hardware operations.
              </ProductFeatureItem>
            </ProductFeature>
          </ProductBody>
          <ProductDisplay></ProductDisplay>
        </ProductSection>
      </Container>
      <div style={{ height: "100px" }} />
    </>
  );
};

export default AppRoute;
