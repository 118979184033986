import React from "react";
import { ArrowRightCircleIcon } from "@heroicons/react/16/solid";
import Container from "../../components/container";
import { Li, Ul } from "../../components/list";
import logo from "./logo.png";
import AppStoreImage from "../../components/apple/app-store-image";
import PlayStoreImage from "../../components/google/play-store-image";
import { TextLink } from "../../components/text";

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  return (
    <Container className="mt-16 mb-8 text-black md:text-white">
      <div className="grid grid-cols-1 md:grid-cols-4">
        <Ul>
          <Li>
            <img src={logo} className="w-44 mb-2" />
          </Li>
          <Li>
            <ArrowRightCircleIcon />
            <TextLink
              href="https://www.linkedin.com/products/juhuu-bikebox"
              className="text-black md:text-white hover:text-white font-normal"
            >
              LinkedIn
            </TextLink>
          </Li>
          <Li>
            <ArrowRightCircleIcon />
            <TextLink
              href="https://www.youtube.com/@juhuu-app"
              className="text-black md:text-white hover:text-white font-normal"
            >
              YouTube
            </TextLink>
          </Li>
        </Ul>
        <Ul>
          <p className="font-bold">General</p>
          <Li>+43 660 9919 009</Li>
          <Li>office@juhuu.app</Li>
          <Li>
            Treustraße 22-24,
            <br /> 1200 Wien, <br />
            Austria
          </Li>
        </Ul>
        <Ul>
          <p className="font-bold">Important</p>
          <Li>
            <TextLink
              href="https://docs.juhuu.app/agb"
              className="text-black md:text-white hover:text-white font-normal"
            >
              General Terms & Conditions
            </TextLink>
          </Li>
          <Li>
            <TextLink
              href="https://docs.juhuu.app/legal/privacy"
              className="text-black md:text-white hover:text-white font-normal"
            >
              Privacy
            </TextLink>
          </Li>
          <Li>
            <TextLink
              href="https://docs.juhuu.app/legal/impressum"
              className="text-black md:text-white hover:text-white font-normal"
            >
              Imprint
            </TextLink>
          </Li>
          <Li>
            <TextLink
              href="https://docs.juhuu.app/legal/cookies"
              className="text-black md:text-white hover:text-white font-normal"
            >
              Cookie Policy
            </TextLink>
          </Li>
        </Ul>
        <Ul>
          <p className="font-bold">Download</p>
          <Li>
            <AppStoreImage className="w-44 mb-2" />
          </Li>
          <Li>
            <PlayStoreImage className="w-44 mb-2" />
          </Li>
        </Ul>
      </div>
      <p className="mt-4">
        © {new Date().getFullYear()} Copyright: JUHUU Bikebox GmbH Apple, the
        Apple logo, iPhone, and iPad are trademarks of Apple Inc., registered in
        the U.S and other countries. App Store is a service mark of Apple Inc.
        Google Play and the Google Play logo are trademarks of Google LLC. All
        other trademarks are the property of their respective owners.
      </p>
    </Container>
  );
};

export default Footer;
